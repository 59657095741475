<div class="show-sequence">
    <h2 class="popup-heading" mat-dialog-title>Show Playlists</h2>
    <mat-progress-bar mode="indeterminate" *ngIf="loading" style="padding: 20px 0"></mat-progress-bar>
    <mat-dialog-content class="mat-typography" *ngIf="!loading">
        <div>
            <h2>Main Audio File Name: <span>{{ (show.audio_file_name ? show.audio_file_name + '.mp3' : 'None') }}</span></h2>
            <h2>Main File Id: <span>{{ show.audio_file_id || 'None' }}</span></h2>
            <h2>Main Playlist: <span>{{ show.playlist || 'None' }}</span></h2>
            <h2>Main Checksum: <span>{{ show.checksum || 'None' }}</span></h2>
            <h2>Main Last Updated: <span>{{ show.last_edited ? (show.last_edited | date:'medium') : 'None' }}</span></h2>
        </div>
        <table mat-table [dataSource]="showSequence">
            <ng-container matColumnDef="file_name">
                <th mat-header-cell *matHeaderCellDef> Audio File Name </th>
                <td mat-cell *matCellDef="let sequence"> {{(sequence.audio_filename ? sequence.audio_filename + '.mp3' : '')}} </td>
            </ng-container>
            <ng-container matColumnDef="file_id">
                <th mat-header-cell *matHeaderCellDef> File Id </th>
                <td mat-cell *matCellDef="let sequence"> {{sequence.audio_file_id}} </td>
            </ng-container>
            <ng-container matColumnDef="last_updated">
                <th mat-header-cell *matHeaderCellDef> Last Updated </th>
                <td mat-cell *matCellDef="let sequence"> {{ sequence.ts | date:'medium'}} </td>
            </ng-container>
            <ng-container matColumnDef="playlist">
                <th mat-header-cell *matHeaderCellDef> Playlist </th>
                <td mat-cell *matCellDef="let sequence"> {{sequence.playlist}} </td>
            </ng-container>
            <ng-container matColumnDef="checksum">
                <th mat-header-cell *matHeaderCellDef> Checksum </th>
                <td mat-cell *matCellDef="let sequence"> {{sequence.checksum}} </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let sequence"> 
                    <button class="popup-save" mat-flat-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" (click)="delete(sequence.show_id ,sequence.audio_file_id)"><span>Delete</span></button> </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>      
        </table>
    </mat-dialog-content>
</div>