<mat-dialog-content class="mat-typography">
	<div>
		<div>
			<p *ngIf="appLink">Please use this URL or QR code to access a web show player which does not require the app. Please note that the timing accuracy of the web player is not as good as the app. You may see 100-200ms+ timing variance on the web player. However, in most cases it should be spot on.
				<span class="link"><a [href]="appLink" target="_blank">{{ appLink }}</a>
                    <a (click)="copyUrl()">
                        <mat-icon>file_copy</mat-icon>
					</a>
				</span>
				<img [src]="this.qrcodeUrl" style="width: 120px; display: block; margin:0 auto 10px auto;" />
				<button mat-raised-button [ngStyle]="{'background-color': 'var(--primary-color)', 'color': 'var(--light-color)'}" class="add-btn" style="display: block; margin:0 auto;" (click)="downloadQR()">Download QR Code</button>
			</p>
			<p *ngIf="!appLink">Error generating app download url. Close the window and then try again.</p>
		</div>
		<!-- <div *ngIf="processing">
				Please wait...
				<mat-progress-bar mode="indeterminate"></mat-progress-bar>
		</div> -->
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button class="cancel-popup" mat-stroked-button (click)="cancel()">Close</button>
</mat-dialog-actions>
