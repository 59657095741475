import { Component, Inject, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ShowsService } from 'src/app/services/shows.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';

@Component({
  selector: 'app-show-sequence',
  templateUrl: './show-sequence.component.html',
  styleUrl: './show-sequence.component.scss'
})
export class ShowSequenceComponent {
  displayedColumns: string[] = ['file_name', 'file_id', 'last_updated', 'playlist', 'checksum', 'action'];
  showSequence = new MatTableDataSource<any>();
  onUpdateSequence = new EventEmitter();
  show: any;
  loading = false;
  constructor(public showsService: ShowsService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data:any) {
      this.loadData();
      this.show = data.show
  }
  ngOnInit(): void {
    this.loading = true;
    this.showsService.getShowSequence(this.show.show_id).subscribe(
      (data: any) => {
        this.loading = false;
        if (data.success && data.sequence) {
          this.showSequence.data = data.sequence;
          if(data.sequence.length > 0){
            this.onUpdateSequence.emit(true);
          }
          else{
            this.onUpdateSequence.emit(false);
          }
        } else {
          console.error('No sequence data available');
        }
      },
      (error) => {
        console.error('Error fetching show sequence data:', error);
      }
    );
  }
  loadData() {
    if (!this.show || !this.show.show_id) {
      console.error('Show data is not available.');
      return;
    }
  
    this.showsService.getShowSequence(this.show.show_id).subscribe(
      (data: any) => {
        this.loading = false;
        if (data.success && data.sequence) {
          this.showSequence.data = data.sequence;
        } else {
          console.error('No sequence data available');
        }
      },
      (error) => {
        console.error('Error fetching show sequence data:', error);
      }
    );
  }
  
	delete(show_id: any, audio_file_id: any) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      id: 'delete-show-sequence',
      maxWidth: '480px',
      width: '100%',
      data: {
        title: 'Confirmation',
        message: 'Are you sure you want to delete this sequence item?'
      }
    });
  
    dialogRef.componentInstance.onConfirm.subscribe(() => {
      this.showsService.destroyShowSequence(this.show.show_id, audio_file_id).subscribe(() => {
        dialogRef.close();
        this.ngOnInit();
      });
    });
  }  
}
