import {Component, Inject, OnInit} from '@angular/core';
import {Show} from "../../interfaces/show.interface";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ShowsService} from "../../services/shows.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-app-link',
  templateUrl: './app-link.component.html',
  styleUrls: ['./app-link.component.scss']
})
export class AppLinkComponent implements OnInit {

	show: Show;
	qrcodeUrl = ''
	processing = true;
	appLink = '';
	constructor(private dialogRef: MatDialogRef<AppLinkComponent>,
							@Inject(MAT_DIALOG_DATA) data:any,
							public snackBar: MatSnackBar,
							private showService: ShowsService) {
		this.show = data.show;
		this.showService.getAppUrl(this.show).subscribe((response:any) => {
			this.processing = false;
			if(response.status && response.status === 'success'){
				this.appLink = response.shortLink;
				this.qrcodeUrl = 'https://qrcode.tec-it.com/API/QRCode?data=' + encodeURIComponent(this.appLink) + '&backcolor=%23ffffff&method=download';
			}
		});
	}

	ngOnInit(): void {
	}
	copyAppLink() {
		const link = this.appLink;
		navigator.clipboard.writeText(link).then(
		  () => {
			this.snackBar.open('App Link copied to clipboard', 'Close', {
				duration: 3000, // Duration in milliseconds
			});
			console.log('App Link copied to clipboard');
		  },
		  err => {
			console.error('Could not copy text: ', err);
		  }
		);
	}
	downloadQR() {
		const qrUrl = this.qrcodeUrl;
		const link = document.createElement('a');
		link.href = qrUrl;
		link.download = 'qrcode.png';
		link.click();
		this.snackBar.open('QR Code Downloaded', 'Close', {
		  duration: 3000,
		});
	  }
	  
	cancel() {
		this.dialogRef.close(false);
	}

}
