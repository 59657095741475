import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatTableModule} from '@angular/material/table';
import { Show } from 'src/app/interfaces/show.interface';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { ShowsService } from 'src/app/services/shows.service';

export interface PeriodicElement {
  parameter: string;
  details: number;
}

const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-show-reporting',
  templateUrl: './show-reporting.component.html',
  styleUrls: ['./show-reporting.component.scss'],
  // standalone: true,
  // imports: [MatTableModule],
})
export class ShowReportingComponent implements OnInit {
  displayedColumns: string[] = ['parameter', 'details'];
  reportingSource = ELEMENT_DATA;
  show:any;
  processing = false;
  constructor(public showService: ShowsService,
    private dialogRef: MatDialogRef<ShowReportingComponent>,
    @Inject(MAT_DIALOG_DATA) data:any,
    public showsService: ShowsService
      ) {
    this.show = data.show;
    this.showsService.getSingleShow(this.show.show_id).subscribe((show: any) => {
      this.show = show;
      this.updateShowData()
    });
    this.updateShowData()
  }

  updateShowData(){
    this.reportingSource = [
      {parameter: 'App Listeners', details: Number(this.show.listeners)},
      {parameter: 'Web Listeners', details: Number(this.show.listeners_web)},
      {parameter: 'App Downloads', details: Number(this.show.downloads)},
      {parameter: 'Web Downloads', details: Number(this.show.downloads_web)},
      {parameter: 'Feedback', details: Number(this.show.feedbacks)},
    ]
  }

  ngOnInit(): void {
    // for(let i = 0; i < 1000; i++){
    //   this.showService.logShowLikes().subscribe((response: any) => {

    //   });
    // }
  }

  close() {
		this.dialogRef.close();
	}
  downloadCsv() {
    if (!this.processing) {
      this.processing = true;
      this.showsService.getShowReport(this.show.show_id).subscribe((response: any) => {
        this.processing = false;
        new AngularCsv(response, this.show.custom_name);
			});
      
    }
  }
}
