import {Component, Inject, OnInit} from '@angular/core';
import {Show} from "../../interfaces/show.interface";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ShowsService} from "../../services/shows.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-web-player-url',
  templateUrl: './web-player-url.component.html',
  styleUrl: './web-player-url.component.scss'
})
export class WebPlayerUrlComponent implements OnInit {

	show: Show;
	qrcodeUrl = ''
	// processing = true;
	appLink = '';
	constructor(private dialogRef: MatDialogRef<WebPlayerUrlComponent>,
							@Inject(MAT_DIALOG_DATA) data:any,
							public snackBar: MatSnackBar,
							private showService: ShowsService) {
		this.show = data.show;
		this.appLink = "https://"+(window.location.hostname.replace("admin.", "web."))+"/"+this.show;
		this.qrcodeUrl = 'https://qrcode.tec-it.com/API/QRCode?data=' + encodeURIComponent(this.appLink) + '&backcolor=%23ffffff&method=download';
		console.log(this.show)
	}

	ngOnInit(): void {
	}
	copyUrl() {
		const link = this.appLink;
		navigator.clipboard.writeText(link).then(
		  () => {
			this.snackBar.open('Web Player URL copied to clipboard', 'Close', {
				duration: 3000,
			});
			console.log('Web Player URL copied to clipboard');
		  },
		  err => {
			console.error('Could not copy Web Player URL: ', err);
		  }
		);
	}
	downloadQR() {
		const qrUrl = this.qrcodeUrl;
		const link = document.createElement('a');
		link.href = qrUrl;
		link.download = 'qrcode.png';
		link.click();
		this.snackBar.open('QR Code Downloaded', 'Close', {
		  duration: 3000,
		});
	  }
	  
	cancel() {
		this.dialogRef.close(false);
	}

}